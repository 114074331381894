@font-face {
  font-family: 'SportingGrotesque';
  src: url('./fonts/SportingGrotesque-Regular.otf') format('opentype');
  /* Add more font file formats (e.g., woff, woff2, ttf) for better browser compatibility */
}

@font-face {
  font-family: 'SportingGrotesque-Bold';
  src: url('./fonts/SportingGrotesque-Bold.otf') format('opentype');
  /* Add more font file formats (e.g., woff, woff2, ttf) for better browser compatibility */
}

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  font-family: 'SportingGrotesque', sans-serif;
  color: rgb(200, 200, 200);
  background-color: black;
}
a:visited, a, body, .fa-icon{
  color: rgb(200, 200, 200);
}
h1{
  font-size: 1.5em;
}
.background, .backdrop,.App{
  min-width: 100%;
  min-height: 100%; 
  position: fixed;
  left: 0;
  top: 0;
}
.background{
  background-image: url('./img/background_cut.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
}

.backdrop{
  backdrop-filter: blur(10px) brightness(.5) grayscale(0.7);
}
.App {
  text-align: center;
  background-color: black;
  position: relative;
  height: 100vh;
  overflow: scroll;
}

.Wrapper{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.Route{
  position: relative;   
  width: min(600px, 100% - 40px);
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  margin: 0 auto;
  /* padding: 30px 20px; */
}
.Route,.Footer{z-index: 10;}
.Header{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  margin: 30px 0;
  width: 100%;
}


.Header img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.LinkCard{
  display: flex;
  flex-direction: column;
  column-gap: 0;
  background-color: rgba(0, 0, 0, 0.4);
  margin: 15px;
  border-radius: 15px;
  border: 1px solid rgb(11, 11, 11);
  overflow: hidden;
  font-size: 0.7em;
}

.mainLink img{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius:  15px 0 0 15px;
}

.mainLink div{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.LinkCard a{
  width: 100%;
  height: 100%;
  text-decoration: none;
  
}
.LinkCard h2{
  font-size: 1em;
}
.mainLink{
  position: relative;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SocialBar{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.SocialBar a{
  display: block;
  margin: 20px;
}
.fa-icon{
  font-size: 1.5em;
}

.center-flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer{
  font-size: 0.8em;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
}
.Footer p{
  margin: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.routerWrapper{
  position: relative;
  min-height: 100vh;

}

.Post{
  padding:30px 20px;
  text-align: left  ;
}

.BackLink{
  text-align: left;
  position:static;
  right: 0;
  width: 100%;
  top: 10px;

}
.Excerpt{
  text-align: left;
  /* margin: 20px; */
  /* border-top: 1px solid rgba(200, 200, 200, 0.5); */
  overflow: hidden;

}

.LinkCard .show-more-button {
  position:absolute;
  top:0px;
  right: 10px;
  background-color: transparent ;
  color: rgb(200, 200, 200);
  border: none;
  width: 40px;
  height: 100%;
  font-size: 2em;
  cursor: pointer;
  
}

/* .LinkCard .show-more-button:hover {
  background-color: #0056b3;
} */

button{	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;}

.LinkCarousel{
  padding-bottom: 30px;
}

.BigLinkCard{
  /* min-height: 200px; */
  padding: 20px 30px;
  overflow: hidden;
}
.BigLinkCard img{
  /* position: relative; */
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}

.BigLinkCard h2{
  font-size: 1.3em;
}
.BigLinkCard .ImageContainer{
  display: inline-block;
  position: relative;
  width: 50%;
}

.BigLinkCard .ImageContainer div{
  margin-top: 100%;
}